<template>
    <div class="CarDriverCarView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div class="insert">
            <van-button type="primary" round plain @click="show_add">添加车辆</van-button>
        </div>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.car_number}}</span>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="车辆大小" :value="v.car_size_type_name" :border="false" />
                <van-cell class="note_cell" title="添加时间" :value="v.create_time" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'

import { car_driver_car_index_request } from '@/network/CarDriverCar'

export default {
    name:'CarDriverCarView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            my_nav_bar_name: '车辆管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            form_key: Date.now(),
            cond: {
                page: 1
            }
        }
    },
    computed:{},
    methods:{
        get_car_driver_car() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            car_driver_car_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        show_details(v) {
            this.$router.push({
                name: 'CarDriverCarDetails',
                query: {
                    id: v.id
                }
            })
        },
        show_add() {
            this.$router.push({
                name: 'CarDriverCarDetails',
                query: {
                    id: 0
                }
            })
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                car_driver_car_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_car_driver_car()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent
    },
    watch:{}
}
</script>

<style>
.CarDriverCarView .van-cell-group {
    margin-bottom: 0.2rem;
}
.CarDriverCarView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.CarDriverCarView .note_cell {
    padding: 0.1rem 0.42667rem
}
.CarDriverCarView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>